import React, { useMemo, useEffect } from 'react';
import cn from 'classnames';
import { keys, size, some } from 'lodash';
import IconSVG from '../../styles/svg-icons';
import { DealerCompany } from './DealerCompany';
import { BrokerDealerCompanySlim } from '../../types/company/BrokerDealerCompanySlim';
import { UserSlim } from '../../types/account/UserSlim';
import { Contact } from '../../types/company/Contact';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../types/state/AppState';
import { dealerListActions } from '../../actions/dealer-list.actions';
import { EditContactPanel } from './EditContact';
import { EmptyPlaceholder } from '../common';
import { Bid } from '../../types/bidding/Bid';
import { apiUtils } from '../../utils/api.utils';
import { SettlementAgentAgreement } from '../../types/bid-as-dealer/SettlementAgentAgreement';
import { useFavorites } from '../../effects/useFavorites';
import { Summary } from "../newBWIC/steps/Summary";
import { Checkbox } from '../controls/Checkbox';
import { roles } from '../../constants';
import { user } from '../../user';
import { arrayUtils } from '../../utils';

interface DealersListProps {
    enableContactOptions?: boolean;
    enableRequestToBid?: boolean;
    selectEnabled?: boolean;
    dealers: BrokerDealerCompanySlim[];
    contacts: Contact[];
    users: UserSlim[];
    bidsByCompany?: Map<number, Bid[]>;
    allSelected?: boolean;
    favoriteButtonDisabled?: boolean;
    onSelectAllChange?: () => void;
    selectedDealers?: { [dealerId: number]: boolean };
    onSelectChange?: (dealerId: number) => void;
    agreements?: SettlementAgentAgreement[];
    noResultsFoundMessage?: string | undefined;
    onResetClick?: () => void;
    selectAllCheckboxDisabled?: boolean;
    liveBidding?: boolean;
}

export const DealerList: React.FC<DealersListProps> = (
    {
        dealers,
        contacts,
        users,
        enableContactOptions = false,
        enableRequestToBid = false,
        selectEnabled = false,
        bidsByCompany,
        allSelected = false,
        favoriteButtonDisabled = false,
        onSelectAllChange,
        selectedDealers,
        onSelectChange,
        agreements,
        noResultsFoundMessage,
        selectAllCheckboxDisabled,
        onResetClick,
        liveBidding = false
    }
) => {
    const dispatch = useDispatch();
    useFavorites(user.hasRoles(...roles.admin(), roles.BrokerDealerTrader));

    const expandedDealers = useSelector((state: AppState) => state.dealerList.expandedDealers);
    const expandedAll = useSelector((state: AppState) => state.dealerList.expandedAll);

    const dealersByExpandableState = useMemo(() =>
        apiUtils.normalize(
            dealers,
            (d: BrokerDealerCompanySlim) => d.id,
            (dealer: BrokerDealerCompanySlim) =>
                !!(
                    dealer.distributionList ||
                    dealer.headOfTrading ||
                    contacts.some(c => c.companyId === dealer.id) ||
                    users.some(u => u.companyId === dealer.id))),
        [dealers, contacts, users]
    );

    const selectedDealersCount = size(selectedDealers);
    const expandAllEnabled = useMemo(() =>
        keys(dealersByExpandableState).some(key => dealersByExpandableState[+key]),
        [dealersByExpandableState]
    );

    useEffect(() => {
        dispatch(dealerListActions.setExpandAllDealers(expandAllEnabled));
    }, [expandAllEnabled, dispatch])

    useEffect(() => {
        if (
            dealers.length &&
            !!size(expandedDealers) &&
            dealers.filter(d => dealersByExpandableState[d.id]).every(d => (expandedDealers[d.id] ?? true) !== expandedAll)
        ) {
            dispatch(dealerListActions.toggleExpandedAllDealers());
        }
    }, [expandedDealers, expandedAll, dealers, dealersByExpandableState, dispatch]);

    const handleToggleCollapseAll = (e: React.MouseEvent) => {
        e.stopPropagation();
        if (expandAllEnabled) {
            const list: { [dealerId: number]: boolean } = expandedAll
                ? apiUtils.normalize(dealers, (d: BrokerDealerCompanySlim) => d.id, () => false)
                : {};

            dispatch(dealerListActions.expandDealers(list, !expandedAll));
        }
    };

    const usersByCompany = useMemo(() => arrayUtils.groupBy(users, u => u.companyId), [users])
    const contactsByCompany = useMemo(() => arrayUtils.groupBy(contacts, c => c.companyId), [contacts]);

    const renderSelectAllCheckbox = () => {
        if (!selectEnabled) {
            return null;
        }
        const isIndeterminate = !allSelected && some(selectedDealers, (d) => d);
        return (
            <Checkbox
                partially={isIndeterminate}
                onChange={(e) => {
                    e.stopPropagation();
                    onSelectAllChange && onSelectAllChange()
                }}
                checked={allSelected}
                disabled={!dealers.length || selectAllCheckboxDisabled}
            />
        )
    };

    const renderCollapseAllButton = () => {
        return (
            <div className="data-table-header-item data-table-item-collapse">
                <span className={cn("btn-link", { disabled: !expandAllEnabled })}
                    onClick={handleToggleCollapseAll}>
                    <IconSVG
                        className={cn({ 'collapse': expandedAll })}
                        name="icon-double-arrows"
                        width={16}
                        height={16}
                    />
                </span>
            </div>
        );
    }

    return (
        <div className="dealers-table flex-row flex-row-full-size">
            <div className="data-list container-flex">
                {!!dealers.length
                    ? (
                        <>
                            <div className="data-list-header flex-row">
                                {renderCollapseAllButton()}
                                {renderSelectAllCheckbox()}
                                {
                                    !selectedDealersCount &&
                                    <>
                                        <div className="data-table-header-item data-list-name">Name</div>
                                        <div className="data-table-header-item data-list-title">Title</div>
                                        <div className="data-table-header-item data-list-email">Email</div>
                                        <div className="data-table-header-item data-list-phone">Phone</div>
                                        <div className="data-table-header-item data-list-options text-right">Actions</div>
                                    </>
                                }
                                {
                                    !!selectedDealersCount && <Summary />
                                }
                            </div>
                            <div className="data-table-content">
                                {
                                    dealers.map(dealer =>
                                        <DealerCompany
                                            key={dealer.id}
                                            dealer={dealer}
                                            expanded={expandedDealers[dealer.id] ?? true}
                                            expandEnabled={dealersByExpandableState[dealer.id]}
                                            contacts={contactsByCompany.get(dealer.id) ?? []}
                                            users={usersByCompany.get(dealer.id) ?? []}
                                            enableContactOptions={enableContactOptions}
                                            enableRequestToBid={enableRequestToBid}
                                            selectEnabled={selectEnabled}
                                            selected={selectedDealers?.[dealer.id] || false}
                                            companyBids={bidsByCompany?.get(dealer.id) ?? []}
                                            favoriteButtonDisabled={favoriteButtonDisabled}
                                            onSelectAllChange={onSelectChange}
                                            agreements={agreements}
                                            liveBidding={liveBidding}
                                        />
                                    )
                                }
                            </div>
                        </>
                    ) : <EmptyPlaceholder onResetClick={onResetClick} text={noResultsFoundMessage} />
                }
                <EditContactPanel />
            </div>
        </div>
    )
};
